<template>
<form novalidate @submit.prevent="validatePeriod">
  <div class="md-layout">
    <div class="md-layout-item md-size-50 md-xsmall-size-100 center">
      <md-card>
        <md-card-header>
        </md-card-header>
        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-xsmall-size-50">
              <md-field >
                <label for="period">Año fiscal</label>
                <md-select v-model="fiscal_year_id" @md-selected="list()">
                <md-option value="">----</md-option>
                <md-option :value="item.id"
                  v-for="(item, key) in list_years"
                  :key="key">{{item.year}}</md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-xsmall-size-50">
              <md-field :class="getValidationClass('period')">
                <label for="type_document">Seleccionar Periodo</label>
                <md-select v-model="instance.period"
                    name="movie"
                    id="movie"
                    :disabled="sending_request"
                  >
                  <md-option :value="item.id"
                    v-for="(item, key) in periods"
                    :key="key">{{item.period}}
                  </md-option>
                  </md-select>
                <span class="md-error"
                  v-if="!$v.instance.period.required">EL periodo es requerido</span>
              </md-field>
            </div>
          </div>
          <div class="centerButton">
            <md-button type="submit" class="md-primary"
              @click="go_to('/detalle_ventas')"
                :disabled="sending_request">Listar ventas</md-button>
          </div>
        </md-card-content>

      </md-card>
    </div>
    <md-progress-bar md-mode="indeterminate" v-if="sending_request" />
  </div>
</form>
</template>
<script>
/* eslint-disable */
import { validationMixin } from 'vuelidate'
import {
  required,
} from 'vuelidate/lib/validators'
export default {
  name: 'Period',
  created(){
    const yearSelected = this.get_data_storage_raw('id_fiscal_year');
    if (yearSelected) {
      this.fiscal_year_id = yearSelected;
    }
    this.get_list_years();
    this.list()
  },
  mixins: [validationMixin],
  components: {
    upload: () => import('../components/Upload.vue'),
  },
  data() {
    return {
      instance: {
        period: null
      },
      fiscal_year_id: null,
      periods: [],
      url: 'periods/',
      url_fiscal_year: 'fiscal_year',
      list_years: [],
      sending_request: false,
    };
  },
  validations: {
    instance: {
      period: {
        required,
      },

    },
  },
  methods: {
    go_to(url) {
      localStorage.removeItem('periodSale');
      this.save_storage('periodSale', this.instance.period);
      this.validatePeriod(url);
    },
    save_storage(key, data) {
      localStorage.setItem([key], data);
    },
    remove_storage(key) {
      localStorage.removeItem([key]);
    },
    get_data_storage(name) {
      return JSON.parse(localStorage.getItem(name));
    },
    get_list_years() {
      const payload = {};
      const self = this;
      this.sendRequest_with_url(this.url_fiscal_year, payload).then((data) => {
        self.sending_request = false;
        self.list_years = data.data;
        console.log(self.list_years)
      }).catch((error) => {
        self.sending_request = false;
        self.handlerError(error);
      });
      return null;
    },
    get_data_storage_raw(name) {
      return localStorage.getItem(name);
    },
    validatePeriod (url) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$router.push(url);
      }
    },
    getValidationClass (fieldName) {
      const field = this.$v.instance[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    list() {
      const payload = {};
      payload.fiscal_year = this.fiscal_year_id;
      this.instance.period = null;
      const self = this;
      if (self.sending_request) return false
      self.sending_request = true
      // payload.fiscal_year = localStorage.getItem('id_fiscal_year')

      self.sendRequest_with_url(this.url, payload).then((data) => {
        self.periods = data.data;
        self.sending_request = false
      }).catch((error) => {
        self.sending_request = false
        self.handlerError(error)
      });
      return null;
    },
    async sendRequest(payload) {
      const result = await this.$http.get(this.url,{params:  payload});
      // const result = await this.$http.get(this.url, payload);
      return result;
    },
    async sendRequest_with_url(url, payload) {
      // const result = await this.$http.get(this.url, payload);
      const result = await this.$http.get(url,{params:  payload});
      return result;
    },
    handlerError: function (error) {
      if (typeof (error.response.data) === 'object') {
        const result = error.response.data
        Object.entries(result).forEach(
          ([key, value]) => this.$swal.fire('Error!', key + ': ' + value, 'error')
        )
        return false
      }
      this.$swal.fire('Error!', error.response.data, 'error')
    },
  },
  filters: {
    status_to_text(value){
      if (value) return 'Sí';
      return 'No';
    }
  },
};

</script>
<style lang="scss" scoped>
  .md-table + .md-table {
    margin-top: 16px
  }
  .avatar img {
    max-width: 30px;
  }
</style>
<style lang="css" scoped>
  .on-over {
    cursor: pointer;
  }
  .fa-refresh {
      -webkit-animation: infinite-spinning 5s normal linear infinite;
      animation: infinite-spinning 5s normal linear infinite;
  }
  .null{
    text-decoration: line-through;
  }
  .fade-enter-active {
    transition: all .5s ease;
  }
  .fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .fade-enter, .fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
  .center{
    margin-left: auto;
    margin-right: auto;
  }
  .centerButton{
    text-align: center;
  }
  @keyframes infinite-spinning {
    0% {
       -webkit-transform: rotate3d(0, 0, 1, 0deg);
       transform: rotate3d(0, 0, 1, 0deg);
     }
     25% {
       -webkit-transform: rotate3d(0, 0, 1, 90deg);
       transform: rotate3d(0, 0, 1, 90deg);
     }
     50% {
       -webkit-transform: rotate3d(0, 0, 1, 180deg);
       transform: rotate3d(0, 0, 1, 180deg);
     }
     75% {
       -webkit-transform: rotate3d(0, 0, 1, 270deg);
       transform: rotate3d(0, 0, 1, 270deg);
     }
     100% {
       -webkit-transform: rotate3d(0, 0, 1, 360deg);
       transform: rotate3d(0, 0, 1, 360deg);
     }
  }
  .bounce-enter-active {
    animation: bounce-in .5s;
  }
  .bounce-leave-active {
    animation: bounce-in .5s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
